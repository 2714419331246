import { darken } from "polished";
import React, { FunctionComponent, MouseEvent } from "react";
import styled from "styled-components";
import Colors from "../../tokens/Colors";
import { rem } from "../../utils/rem";
import Icon, { Icons } from "../icon/Icon";

interface TextButtonProps {
  icon: Icons;
  emphasized?: boolean;
  className?: string;
  onClick?: (event: MouseEvent) => void;
  href?: string;
}

const TextButton: FunctionComponent<TextButtonProps> = ({
  className,
  children,
  icon,
  emphasized,
  onClick,
  href = "",
}) => (
  <StyledButtonWrapper>
    <StyledAnchor
      href={href}
      className={className}
      onClick={onClick}
      emphasized={!!emphasized}
      target="_blank"
      rel="noreferrer"
    >
      <StyledLabel>{children}</StyledLabel>
      <StyledIconWrapper emphasized={!!emphasized}>
        <StyledIcon icon={icon} />
      </StyledIconWrapper>
    </StyledAnchor>
  </StyledButtonWrapper>
);

const StyledLabel = styled.span`
  transform: translateY(3px);
`;

const StyledIcon = styled(Icon)`
  width: ${rem(12)};
`;

const StyledAnchor = styled.a<{ emphasized: boolean }>`
  color: ${Colors.WHITE};
  font-family: "Trade Gothic", Arial, sans-serif;
  font-style: oblique;
  font-weight: bold;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    text-decoration: underline;

    > div {
      background: ${({ emphasized }) =>
        emphasized
          ? darken(0.2, Colors.GREEN_YELLOW)
          : "rgba(255, 255, 255, 0.4)"};
    }
  }
`;

const StyledButtonWrapper = styled.span`
  display: flex;
`;

const StyledIconWrapper = styled.div<{ emphasized: boolean }>`
  transition: background-color 0.2s ease-in-out;
  width: ${rem(30)};
  height: ${rem(30)};
  background: ${({ emphasized }) =>
    emphasized ? Colors.GREEN_YELLOW : "rgba(255, 255, 255, 0.24)"};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: ${rem(10)};
`;

export default TextButton;
