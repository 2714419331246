import React, { FunctionComponent } from "react";
import styled from "styled-components";
import Container from "../../container/Container";
import useBreakPoints from "../../hooks/use-breakpoints/useBreakPoints";
import getUTMLink from "../../utils/getUTMLink";
import { mediaQueries } from "../../utils/mediaQueries";
import { rem } from "../../utils/rem";
import Grid, { GridItem } from "../grid/Grid";
import { Icons } from "../icon/Icon";
import TextButton from "../text-button/TextButton";
import lineMobile from "./images/line-mobile.svg";
import line from "./images/line.svg";
import logoIMCTrading from "./images/logo-imc-trading.svg";
import logoTeamLiquid from "./images/logo-team-liquid.svg";

interface HeaderProps {
  className?: string;
}

const Header: FunctionComponent<HeaderProps> = ({ className }) => {
  const { isMobile } = useBreakPoints();

  return (
    <StyledContainer className={className}>
      <StyledGrid>
        <StyledLogoGridItem
          span={{
            mobile: 4,
            tablet: 4,
            desktop: 11,
          }}
        >
          <a
            href={getUTMLink("https://careers.imc.com", "logo")}
            target="_blank"
            rel="noreferrer"
          >
            <StyledIMCLogoImage src={logoIMCTrading} alt="IMC trading logo" />
          </a>
          <StyledLine src={isMobile ? lineMobile : line} alt="line" />
          <a
            href="https://www.teamliquid.com/"
            target="_blank"
            rel="noreferrer"
          >
            <StyledTeamLiquidLogoImage
              src={logoTeamLiquid}
              alt="Team Liquid logo"
            />
          </a>
        </StyledLogoGridItem>

        <StyledGridItem
          span={{
            mobile: 2,
            tablet: 2,
            desktop: 1,
          }}
        >
          <StyledTextButton
            icon={Icons.ARROW_UP_RIGHT}
            emphasized={true}
            href={getUTMLink("https://careers.imc.com", "careers-1")}
          >
            Careers <br /> at IMC
          </StyledTextButton>
        </StyledGridItem>
      </StyledGrid>
    </StyledContainer>
  );
};

const StyledTextButton = styled(TextButton)`
  font-size: ${rem(12)};
  line-height: 1.17;
  font-style: normal;
`;

const StyledGrid = styled(Grid)`
  height: 100%;
  align-items: center;
`;

const StyledLine = styled.img`
  margin: 0 ${rem(5)};

  ${mediaQueries.desktop} {
    margin: 0 ${rem(20)};
  }
`;

const StyledIMCLogoImage = styled.img`
  width: ${rem(56)};

  ${mediaQueries.desktop} {
    width: ${rem(105)};
  }
`;

const StyledTeamLiquidLogoImage = styled.img`
  width: ${rem(65)};

  ${mediaQueries.desktop} {
    width: ${rem(112)};
  }
`;

const StyledContainer = styled(Container)`
  height: ${rem(70)};
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 2;

  ${mediaQueries.desktop} {
    height: ${rem(120)};
  }
`;

const StyledLogoGridItem = styled(GridItem)`
  display: flex;
  align-items: center;
  height: 100%;
`;

const StyledGridItem = styled(GridItem)`
  display: flex;
  justify-content: flex-end;
`;

export default Header;
