import { breakpoints } from "../../utils/mediaQueries";
import useWindowSize from "../use-window-size/useWindowSize";

interface Breakpoints {
  isMobile: boolean;
  isDesktop: boolean;
  isTablet: boolean;
  isUltrawide: boolean;
}

const useBreakPoints = (): Breakpoints => {
  const [width] = useWindowSize();

  return {
    isMobile: width < breakpoints.tablet,
    isTablet: width >= breakpoints.tablet && width < breakpoints.desktop,
    isDesktop: width >= breakpoints.desktop && width < breakpoints.ultrawide,
    isUltrawide: width >= breakpoints.ultrawide,
  };
};

export default useBreakPoints;
