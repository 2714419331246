import { useLayoutEffect, useState } from "react";
import throttle from "lodash/throttle";

// https://usehooks.com/useWindowSize/
const useWindowSize = (): number[] => {
  const [windowSize, setWindowSize] = useState<number[]>([]);

  useLayoutEffect(() => {
    // Handler to call on window resize
    const handleResize = (): void => {
      // Set window width/height to state
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    const throttledHandleResize = throttle(handleResize, 200, {
      leading: false,
    });

    // Add event listeners
    window.addEventListener("resize", throttledHandleResize);
    window.addEventListener("orientationchange", throttledHandleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return (): void => {
      window.removeEventListener("resize", throttledHandleResize);
      window.removeEventListener("orientationchange", throttledHandleResize);
    };
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
};

export default useWindowSize;
